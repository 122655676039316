<template>
  <div class="dropdown-wrapper">
    <div class="dropdown-selected-option" @click="isOpen = !isOpen">
      <button class="lang-btn">
        <img id="flag" :src="selectedFlag" alt="" />
        {{ selectedLanguage }}
      </button>
      <img id="dropdown-arrow" src="../../public/img/dropdown.svg" alt="" />
    </div>
    <div class="options-wrapper" v-if="isOpen">
      <div v-for="language in languages" :key="language.index" class="option-item">
        <button class="lang-btn" @click="selectLanguage(language)">
          <img id="flag" :src="language.flag" alt="" />
          {{ language.title }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import gb from "@/assets/flags/gb.svg";
import es from "@/assets/flags/es.svg";
import pt from "@/assets/flags/pt.svg";

export default {
  name: "languageSelector",
  data() {
    return {
      isOpen: false,
      selectedFlag: gb, //default flag
      selectedLanguage: "EN", //default language
      languages: [
        {
          title: "EN",
          flag: gb,
        },
        {
          title: "ES",
          flag: es,
        },
        {
          title: "PT",
          flag: pt,
        },
      ],
    };
  },
  methods: {
    selectLanguage(language) {
      console.log(language);
      this.selectedLanguage = language.title;
      this.selectedFlag = language.flag;
      this.isOpen = false;
      this.$store.commit("SET_LANGUAGE", language.title);
      console.log("The selected language is: ", this.selectedLanguage);
    },
  },
};
</script>

<style>
.dropdown-wrapper {
  width: 120px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
}

.dropdown-selected-option {
  margin-top: 25px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
}

.lang-btn {
  border: none;
  background-color: transparent;
  color: #fff;
  font-size: 18px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  padding: 5px 5px;
}

.lang-btn:hover {
  cursor: pointer;
}

.option-item:hover {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.1);
}

.options-wrapper {
  position: absolute;
  margin-top: 60px;
}

#flag {
  height: 20px;
}

#dropdown-arrow {
  height: 10px;
  padding-left: 0px;
  margin-bottom: 3px;
}
</style>
