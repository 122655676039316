<template>
  <div id="three-container"></div>
</template>

<script>
  import * as THREE from 'three';

  export default {
    name: 'GalaxyComponent',
    mounted() {
      // These variables can be used to tune visual parameters
      var starSize = 1;
      var pointStarsStop = 150;
      var initialAcceleration = 0.08;
      var initialVelocity = 0;

      const scene = new THREE.Scene();
      const camera = new THREE.PerspectiveCamera(60, window.innerWidth / window.innerHeight, 1, 1000);
      camera.position.z = 1;
      camera.rotation.x = Math.PI / 2;

      const renderer = new THREE.WebGLRenderer({ alpha: true });
      renderer.setSize(window.innerWidth, window.innerHeight);
      renderer.setClearColor(0x000000, 0);
      document.getElementById('three-container').appendChild(renderer.domElement);

      let starGeo;
      starGeo = new THREE.BufferGeometry();
      const positions = [];
      const velocities = [];
      const accelerations = [];

      for (let i = 0; i < 5000; i++) {
        const star = new THREE.Vector3(
          Math.random() * 600 - 300,
          Math.random() * 600 - 300,
          Math.random() * 600 - 300
        );
        velocities.push(initialVelocity)
        accelerations.push(initialAcceleration);
        positions.push(star.x, star.y, star.z);
      }

      starGeo.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
      starGeo.setAttribute('velocity', new THREE.Float32BufferAttribute(velocities, 3));
      starGeo.setAttribute('acceleration', new THREE.Float32BufferAttribute(accelerations, 3));

      new THREE.ImageBitmapLoader().load(
        // resource URL
        'img/circle.png',

        // onLoad callback
        function (imageBitmap) {
          const texture = new THREE.CanvasTexture(imageBitmap);
          //  const material = new THREE.PointsMaterial({ map: texture });

          const starMaterial = new THREE.PointsMaterial({ color: 0xaaaaaa, transparent: true, alphaTest: 0.5, size: starSize, map: texture });

          const stars = new THREE.Points(starGeo, starMaterial);
          scene.add(stars);
          animate();
        },

        // onProgress callback currently not supported
        undefined,

        // onError callback
        function (err) {
          console.log('An error happened', err);
        }
      );

      window.addEventListener('resize', onWindowResize);

      function animate() {
        const positions = starGeo.attributes.position.array;
        const velocities = starGeo.attributes.velocity.array;
        const accelerations = starGeo.attributes.acceleration.array;

        for (let i = 0; i < positions.length; i += 3) {
          velocities[i / 3] += accelerations[i / 3];
          positions[i + 1] -= velocities[i / 3];

          // check if the star has fallen below the chosen distance, reset its position and velocity
          if (positions[i + 1] < pointStarsStop) {
            positions[i + 1] = 200;
            velocities[i / 3] = 0;

            // randomize z-coordinate of stars some
            positions[i + 1] = Math.random() * 600 - 300;
          }
        }

        starGeo.attributes.position.needsUpdate = true;
        starGeo.attributes.velocity.needsUpdate = true;

        renderer.render(scene, camera);
        requestAnimationFrame(animate);
      }

      function onWindowResize() {
        const width = window.innerWidth;
        const height = window.innerHeight;

        camera.aspect = width / height;
        camera.updateProjectionMatrix();

        renderer.setSize(width, height);
      }
    }
  };
</script>

<style scoped>
  #three-container {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }
</style>