import { createStore } from "vuex";
import EN from "./lang/en/en.json";
import ES from "./lang/es/es.json";
import PT from "./lang/pt/pt.json";

export default createStore({
  state: {
    languageData: EN,
  },
  mutations: {
    SET_LANGUAGE(state, language) {
      if (language === "EN") {
        state.languageData = EN;
      } else if (language === "ES") {
        state.languageData = ES;
      } else if (language === "PT") {
        state.languageData = PT;
      }
      console.log("The current language data is: ", this.languageData);
    },
  },
});
