<template>
  <Transition name="galaxy">
    <GalaxyComponent v-show="show" />
  </Transition>
  <div class="container">
    <div class="">
      <div class="wave-background">
        <Transition name="textContainer">
          <div class="layout-1" v-if="stateIndex == 0 && !show">
            <nav>
              <div id="nav-spacer-home"></div>
              <button id="home-btn" @click="resetState"><img id="logo" src="../public/img/hid-logo.png" alt="" /></button>
              <languageSelector />
            </nav>
            <div class="middle">
              <h1>{{ titles.splashTitle }}</h1>
              <button class="yellow-btn" @click="nextState">{{ titles.splashButton }}</button>
            </div>
          </div>
        </Transition>
      </div>

      <div class="layout-2" v-if="stateIndex == 1">
        <nav class="topLayer">
          <div id="nav-spacer">
            <div v-if="questionIndex > 0">
              <button id="back-btn" @click="backButton"><img id="back-btn-img" src="../public/img/back-btn.png" alt="" /></button>
            </div>
          </div>
          <button id="home-btn" @click="resetState"><img id="logo" src="../public/img/hid-logo.png" alt="" /></button>
          <div id="nav-spacer"></div>
        </nav>

        <Transition name="textContainer">
          <div v-show="!show">
            <div class="middle topLayer">
              <div>
                <h3>{{ titles.questionTitle }} {{ questionIndex + 1 }} / 11</h3>
              </div>
              <div v-for="question in questions" :key="question.index">
                <div v-if="questionIndex == question.index" class="btn-container">
                  <h1>
                    {{ question.text }}
                  </h1>
                  <div class="btn-container">
                    <button class="yellow-btn-expandable" @click="pressAnswer(answer.points)" v-for="answer in question.answers" :key="answer.text">
                      {{ answer.text }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Transition>

        <div class="bottom progress-container">
          <div class="progress-bar-title">
            <p v-if="currentPoints < 30" style="opacity: 1">{{ titles.rank[0] }}</p>
            <p v-else style="opacity: 0.3">{{ titles.rank[0] }}</p>
            <p v-if="currentPoints >= 30 && currentPoints < 60" style="opacity: 1">{{ titles.rank[1] }}</p>
            <p v-else style="opacity: 0.3">{{ titles.rank[1] }}</p>
            <p v-if="currentPoints >= 60" style="opacity: 1">{{ titles.rank[2] }}</p>
            <p v-else style="opacity: 0.3">{{ titles.rank[2] }}</p>
          </div>
          <div class="white-progress-bar">
            <div v-if="dotIsYellow(0.0)" id="firstDot" style="background-color: #edc417; left: 0%" class="one"></div>
            <div v-else id="backupFirstDot" style="background-color: white; left: 0%" class="one"></div>

            <div v-if="dotIsYellow(0.33)" style="background-color: #edc417; left: 33%" class="one"></div>
            <div v-else style="background-color: white; left: 33%" class="one"></div>

            <div v-if="dotIsYellow(0.66)" style="background-color: #edc417; left: 66%" class="one"></div>
            <div v-else style="background-color: white; left: 66%" class="one"></div>

            <div v-if="dotIsYellow(0.99)" style="background-color: #edc417; left: 100%" class="one"></div>
            <div v-else style="background-color: white; left: 100%" class="one"></div>

            <div class="yellow-progress-bar" :style="{ width: progressBarWidth }"></div>
          </div>
        </div>
      </div>

      <div class="layout-1 topLayer" v-if="stateIndex == 2">
        <nav>
          <div id="nav-spacer"></div>
          <button id="home-btn" @click="resetState"><img id="logo" src="../public/img/hid-logo.png" alt="" /></button>
          <div id="nav-spacer"></div>
        </nav>
        <div class="middle">
          <div class="lds-roller">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <h1>{{ titles.resultsLoadingTitle }}</h1>
        </div>
      </div>

      <div class="layout-3" v-if="stateIndex >= 3">
        <nav>
          <div id="nav-spacer"></div>
          <button id="home-btn" @click="resetState"><img id="logo" src="../public/img/hid-logo.png" alt="" /></button>
          <button id="x-btn" @click="resetState"><img id="x-img" src="../public/img/x-btn2.png" alt="" /></button>
        </nav>

        <div class="middle">
          <div class="star-container">
            <div>
              <img id="star-img" src="../public/img/star.png" alt="" />
            </div>
            <div v-if="currentPoints > 30">
              <img id="star-img" src="../public/img/star.png" alt="" />
            </div>
            <div v-if="currentPoints > 60">
              <img id="star-img" src="../public/img/star.png" alt="" />
            </div>
          </div>
          <div class="progress-container">
            <div class="white-progress-bar">
              <div v-if="dotIsYellow(0.0)" id="firstDot" style="background-color: #edc417; left: 0%" class="one"></div>
              <div v-else id="backupFirstDot" style="background-color: white; left: 0%" class="one"></div>

              <div v-if="dotIsYellow(0.33)" style="background-color: #edc417; left: 33%" class="one"></div>
              <div v-else style="background-color: white; left: 33%" class="one"></div>

              <div v-if="dotIsYellow(0.66)" style="background-color: #edc417; left: 66%" class="one"></div>
              <div v-else style="background-color: white; left: 66%" class="one"></div>

              <div v-if="dotIsYellow(0.99)" style="background-color: #edc417; left: 100%" class="one"></div>
              <div v-else style="background-color: white; left: 100%" class="one"></div>
              <div class="yellow-progress-bar" :style="{ width: progressBarWidth }"></div>
            </div>
          </div>
          <h1>{{ userResults.title }}</h1>
          <div v-if="stateIndex == 3">
            <p class="results-text" v-for="paragraph in userResults.paragraphs" :key="paragraph.index">{{ paragraph.text }}</p>
          </div>

          <div class="flex-center" v-if="stateIndex == 4">
            <p class="email-form-text">{{ titles.emailFormText }}</p>
            <input v-model="emailToAdd" type="email" id="email" />
          </div>
        </div>

        <div class="bottom">
          <div v-if="stateIndex == 3">
            <button class="yellow-btn" @click="nextState">{{ titles.emailResultsButton }}</button>
          </div>
          <div class="disclaimer" v-if="stateIndex == 4">
            <p>{{ titles.disclaimer }}</p>
            <button class="yellow-btn" @click="emailResults">{{ titles.doneButton }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <form style="display: none" id="mktoForm_7016"></form>
</template>
<script>
import GalaxyComponent from "./components/galaxy.vue";
import languageSelector from "./components/dropdown.vue";

export default {
  name: "App",
  components: {
    GalaxyComponent,
    languageSelector,
  },

  mounted() {
    // Create a script element
    let mktoFormsScript = document.createElement("script");

    // Set the source attribute to the Marketo Forms2 script URL
    mktoFormsScript.setAttribute("src", "//info.hidglobal.com/js/forms2/js/forms2.min.js");

    // Append the script element to the document's head
    document.head.appendChild(mktoFormsScript);

    // Define a function to load the Marketo form
    const loadMarketoForm = () => {
      window.MktoForms2.loadForm("//info.hidglobal.com", "289-TSC-352", 7016);
    };

    // Add an event listener for the script's load event
    mktoFormsScript.addEventListener("load", loadMarketoForm);
  },
  methods: {
    dotIsYellow: function (ratio) {
      if (this.currentPoints / this.maxPoints > ratio) {
        return true;
      } else {
        return false;
      }
    },
    pressAnswer: function (points) {
      this.prevQuestionPoints = points;

      this.currentPoints += points;

      if (this.questionIndex == this.questions.length - 1) {
        this.questionIndex += 1;
        this.resultsSet.forEach((result) => {
          if (this.currentPoints >= result.minPoints && this.currentPoints <= result.maxPoints) {
            this.userResults = result;
          }
        });
        this.stateIndex += 1;
        setTimeout(() => {
          this.stateIndex += 1;
        }, 2000);
      } else {
        this.show = true;
        setTimeout(() => {
          this.show = false;
          this.questionIndex += 1;
        }, 700);
      }
    },
    backButton: function () {
      this.questionIndex -= 1;
      this.currentPoints -= this.prevQuestionPoints;
    },
    transitionBackground: function () {
      this.show = true;
      setTimeout(() => {
        this.show = false;
      }, 700); //sets transition time
    },
    emailResults: function () {
      document.getElementById("event2").value = this.currentPoints;
      document.getElementById("event1").value = this.userResults.name;
      document.getElementById("Email").value = this.emailToAdd;
      const embeddedSubmitButton = document.querySelector(".mktoButton");
      if (embeddedSubmitButton) {
        embeddedSubmitButton.click();
      } else {
        console.error("Embedded submit button not found.");
      }
      this.resetState();
    },
    nextState: function () {
      this.stateIndex += 1;
      this.transitionBackground();
    },
    resetState: function () {
      this.stateIndex = 0;
      this.questionIndex = 0;
      this.emailToAdd = "";
      this.currentPoints = 0;
      this.$store.commit("SET_LANGUAGE", "EN");
    },
    validateEmail() {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(this.emailToAdd);
    },
    async addEmailToStorage() {
      if (this.emailToAdd === "downloademails") {
        this.downloadEmailListAsCSV();
        return;
      }
      if (!this.validateEmail()) {
        console.log("Invalid email address");
        this.resetState();
        return;
      }
      const email = this.emailToAdd;
      const data = { email, points: this.currentPoints, name: this.userResults.name };
      try {
        const db = await this.openDB();
        const transaction = db.transaction("emails", "readwrite");
        const store = transaction.objectStore("emails");
        await store.add(data);
        console.log("Data added to IndexedDB");
      } catch (error) {
        console.error("Error adding data to IndexedDB:", error);
      }
      this.resetState();
    },

    async downloadEmailListAsCSV() {
      try {
        const db = await this.openDB();
        const transaction = db.transaction("emails", "readonly");
        const store = transaction.objectStore("emails");
        const request = store.getAll();
        request.onsuccess = (event) => {
          const data = event.target.result;

          if (!data || data.length === 0) {
            console.log("No data found in IndexedDB");
            return;
          }

          // Convert data to CSV
          let csvData = "Email,Points,Name\n";
          data.forEach((entry) => {
            csvData += `${entry.email},${entry.points},${entry.name}\n`;
          });

          // Download CSV
          const blob = new Blob([csvData], { type: "text/csv" });
          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = "HID_email_list.csv";
          link.click();
          alert("Data list downloaded as CSV");
        };
      } catch (error) {
        console.log("Error downloading data list as CSV:", error);
      }
    },

    async deleteAllEmailData() {
      try {
        const db = await this.openDB();
        const transaction = db.transaction("emails", "readwrite");
        const store = transaction.objectStore("emails");
        await store.clear();
        console.log("All email data deleted from IndexedDB");
      } catch (error) {
        console.error("Error deleting email data from IndexedDB:", error);
      }
    },

    async openDB() {
      return new Promise((resolve, reject) => {
        const request = window.indexedDB.open("emailDB", 1);
        request.onerror = () => reject(request.error);
        request.onsuccess = () => resolve(request.result);
        request.onupgradeneeded = (event) => {
          const db = event.target.result;
          db.createObjectStore("emails", { keyPath: "email" });
        };
      });
    },
  },
  computed: {
    progressBarWidth: function () {
      return (this.currentPoints / this.maxPoints) * 100 + "%";
    },

    getProgressBarColor: function () {
      return function (ratio) {
        if (this.currentPoints / this.maxPoints > ratio) {
          return "#EDC417";
        } else {
          return "white";
        }
      };
    },

    questions() {
      return this.$store.state.languageData.questions;
    },

    resultsSet() {
      return this.$store.state.languageData.resultsSet;
    },

    titles() {
      return this.$store.state.languageData.titles;
    },
  },

  data() {
    return {
      stateIndex: 0,
      questionIndex: 0,
      currentPoints: 0,
      prevQuestionPoints: 0,
      maxPoints: 80,
      emailToAdd: "",
      show: false,
    };
  },
};
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  margin: 0px;
  overflow: hidden;
}

* {
  box-sizing: border-box;
  margin: 0px;
  touch-action: none;
  -ms-touch-action: none;
  padding: 0px;
}

.container {
  height: 100vh;
  z-index: -10;
  margin: 0 auto;
  padding: 0 30px;
  background: var(--Gradient-01, linear-gradient(180deg, #002d56 38.38%, #0057b7 132.81%));
}

#three-container {
  position: absolute;
  width: 10vw;
  height: 10vh;
  left: 0;
  top: 0;
  z-index: 50;
  overflow: hidden;
}

.topLayer {
  z-index: 1000;
  position: relative; /* Change position to relative */
}

#three-container {
  position: absolute;
  width: 10vw;
  height: 10vh;
  left: 0;
  top: 0;
  z-index: 50;
  overflow: hidden;
}

.topLayer {
  z-index: 1000;
  position: relative; /* Change position to relative */
}

nav {
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
}

#nav-spacer {
  width: 0px;
  margin: auto 20px;
}

#nav-spacer-home {
  width: 100px;
  margin: auto 20px;
}

#logo {
  height: 40px;
  /* margin-left: auto;
    margin-right: auto; */
}

#x-btn {
  background-color: transparent;
  border: none;
}

#x-btn:hover {
  cursor: pointer;
}

#home-btn {
  background-color: transparent;
  border: none;
}

#home-btn:hover {
  cursor: pointer;
}

#lang-select-btn {
  background-color: transparent;
  border: none;
}

#lang-uk {
  height: 25px;
  margin: 0 20px;
}

#x-img {
  height: 30px;
}

#back-btn {
  background-color: transparent;
  border: none;
}

#back-btn:hover {
  cursor: pointer;
}

#back-btn-img {
  height: 25px;
}

.layout-1 {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
}

.wave-background {
  background-image: url("../public/img/waves.png");
  background-size: 100% 50%;
  background-position: bottom;
  background-repeat: no-repeat;
  position: relative;
  margin: 0px -30px; /* Override parent padding - can we remove padding on the container div? */
}

.layout-1 .middle {
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;
}

.layout-2 {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
}

.layout-2 .middle {
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.layout-2 .bottom {
  margin-bottom: 50px;
}

.layout-3 {
  height: 100vh;
  widows: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
}

.layout-3 .middle {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.layout-3 .bottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.flex-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

h1 {
  font-family: "neue-haas-unica", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 2.5rem;
  line-height: normal;
  margin-bottom: 30px;
  /* white-space: pre; */
  text-align: center;
  max-width: 600px;
}

p {
  font-family: "neue-haas-unica", sans-serif;
  font-style: normal;
  font-weight: 300;
}

h3 {
  font-family: "neue-haas-unica", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 1.1rem;
  letter-spacing: 0.15rem;
  margin-bottom: 20px;
}

.yellow-btn-expandable {
  background-color: #edc417;
  color: #002d56;
  font-family: "neue-haas-unica", sans-serif;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 600;
  flex-grow: 1; /* Make buttons grow to fill available space */
  min-width: 25rem; /* Ensure buttons can shrink if needed */
  width: 100%; /* Initially, buttons occupy all available space */
  height: 3.5rem;
  border-radius: 6rem;
  border: none;
  margin: 10px;
  padding: 10px 20px;
}

.yellow-btn-expandable:hover {
  cursor: pointer;
}

.yellow-btn {
  background-color: #edc417;
  color: #002d56;
  font-family: "neue-haas-unica", sans-serif;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 600;
  width: 25rem;
  height: 3.5rem;
  border-radius: 6rem;
  border: none;
  margin: 10px;
  padding: 10px 20px;
}

.yellow-btn:hover {
  cursor: pointer;
}

.btn-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.results-text {
  text-align: left;
  padding-bottom: 30px;
  font-size: 1.2rem;
  font-weight: 300;
  max-width: 600px;
}

.email-form-text {
  text-align: center;
  padding-bottom: 30px;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 350;
  line-height: normal;
  letter-spacing: 0.05rem;
  max-width: 500px;
}

input {
  width: 400px;
  height: 50px;
  padding: 10px;
  border-radius: 8px;
  font-size: 1.4rem;
  border: none;
  margin-bottom: 20px;
}

.disclaimer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.disclaimer p {
  font-size: 1rem;
  max-width: 600px;
  margin-bottom: 20px;
  text-align: center;
}

.galaxy-enter-active,
.galaxy-leave-active {
  transition: opacity 0.6s cubic-bezier(1, 0.5, 0.8, 1);
}

/* start and end value of galaxy opacity */
.galaxy-enter-from,
.galaxy-leave-to {
  opacity: 0;
}

.textContainer-enter-active {
  transition: opacity 0.3s ease-in;
  animation: scale-in 0.5s;
}

.textContainer-leave-active {
  transition: opacity 0.3s ease-out;
  animation: scale-out 0.5s;
}

@keyframes scale-in {
  0% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes scale-out {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.5);
  }
}

.textContainer-enter-from,
.textContainer-leave-to {
  opacity: 0;
}

.one,
.two,
.three,
.four {
  position: absolute;
  z-index: 1;
  border-radius: 25px;
  background-color: #f0ad4e;
}

.one,
.four {
  margin-top: -10px;
  margin-left: -10px;
  height: 25px;
  width: 25px;
}

.two,
.three {
  margin-top: -10px;
  margin-left: -20px;
  height: 25px;
  width: 25px;
}

.white-progress-bar {
  height: 7px;
  /* width: 500px; */
  width: 100%;
  margin-bottom: 20px;
  overflow: hidden;
  background-color: white;
}

.yellow-progress-bar {
  float: left;
  width: 0%;
  height: 100%;
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
  text-align: center;
  background-color: #edc417;
}

.progress-container {
  position: relative;
  width: 500px;
  margin: 0px auto;
}

.progress-bar-title {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 25px;
  letter-spacing: 0.15rem;
  font-size: 1.1rem;
}

#star-img {
  height: 90px;
  margin: 10px;
}

.star-container {
  display: flex;
  flex-direction: row;
  margin: 20px 0px;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fff;
  margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Tablets and under */
@media (max-width: 768) {
}

/* Mobile */
@media (max-width: 500px) {
  .container {
    padding: 0 10px;
  }

  h1 {
    font-size: 1.6rem;
  }

  .yellow-btn {
    width: 20rem;
  }

  .yellow-btn-expandable {
    min-width: 20rem;
    font-size: 1rem;
  }

  #star-img {
    height: 70px;
  }

  .progress-container {
    width: 350px;
  }

  .progress-bar-title {
    font-size: 0.8rem;
  }

  .results-text {
    font-size: 1rem;
    margin: 0 30px;
  }

  .email-form-text {
    font-size: 1.1rem;
  }

  .disclaimer p {
    font-size: 0.7rem;
    text-align: center;
  }

  input {
    width: 350px;
  }

  .layout-2 .bottom {
    margin-bottom: 100px;
  }

  .layout-3 .bottom {
    margin-bottom: 100px;
  }
}

@media (max-width: 380px) {
  h1 {
    font-size: 1.3rem;
  }

  h3 {
    font-size: 0.8rem;
  }

  .yellow-btn {
    width: 20rem;
    font-size: 1rem;
  }

  .yellow-btn-expandable {
    width: 20rem;
    font-size: 0.9rem;
  }

  .progress-container {
    width: 300px;
  }

  #star-img {
    height: 50px;
  }

  .layout-2 .bottom {
    margin-bottom: 100px;
  }

  .layout-3 .bottom {
    margin-bottom: 100px;
  }
}
</style>
